import React from 'react'
import PropTypes from 'prop-types'
import {
  TemplateBreadCrumbs,
  Typography,
  Divider,
  PressOfficeWidget,
  Social,
  PinBlockGroup,
  AggregatorPin4,
} from '../../components'
import { graphql } from 'gatsby'
import ContentRightSidebar from '../../components/Layout/ContentRightSidebar/ContentRightSidebar'
import { flattenNodes } from '../../utils'
import styles from './ScholarPageTemplate.module.css'
import { pathOr } from 'ramda'

const ScholarPage = ({ data }) => {
  const {
    aboutPage: {
      frontmatter: { header },
    },
    // categories,
  } = data

  const breadcrumbs = [
    { label: 'About Us', url: '/about-us/' },
    { label: 'Our Scholars' },
  ]

  const profiles = flattenNodes(data.profiles.edges)
  const profilesOnlyScholarsPre2022 = profiles.filter(p =>
    pathOr([], ['teamCategory'], p)
      .join(',')
      .toLowerCase()
      .includes('(2020/2021)'),
  )
  const profilesOnlyScholars2022 = profiles.filter(p =>
    pathOr([], ['teamCategory'], p).join(',').toLowerCase().includes('(2022)'),
  )
  const profilesOnlyScholars2023 = profiles.filter(p =>
    pathOr([], ['teamCategory'], p).join(',').toLowerCase().includes('(2023)'),
  )

  const profilesOnlyScholars2024 = profiles.filter(p =>
    pathOr([], ['teamCategory'], p).join(',').toLowerCase().includes('(2024)'),
  )

  return (
    <>
      <ContentRightSidebar header={header}>
        <div>
          <TemplateBreadCrumbs breadcrumbs={breadcrumbs} />

          <Divider thickness={4} />
          <Typography tag="h1" type="boxHeading" className="mb-8">
            BERTHA CENTRE SCHOLARS (2024)
          </Typography>
          <div>
            <PinBlockGroup key={1} columns={2}>
              {profilesOnlyScholars2024.map((profile, i) => {
                return (
                  <>
                    <AggregatorPin4
                      key={i}
                      title={profile.title}
                      thumbnailSrc={profile.featuredImage}
                      href={profile.slug}
                      className={styles.teamPin}
                    >
                      {profile.additionalInfo && (
                        <strong>{profile.additionalInfo}</strong>
                      )}
                      <p>{profile.excerpt}...</p>
                    </AggregatorPin4>
                  </>
                )
              })}
            </PinBlockGroup>
          </div>

          <Divider thickness={4} />
          <Typography tag="h1" type="boxHeading" className="mb-8">
            BERTHA CENTRE SCHOLARS (2023)
          </Typography>
          <div>
            <PinBlockGroup key={1} columns={2}>
              {profilesOnlyScholars2023.map((profile, i) => {
                return (
                  <>
                    <AggregatorPin4
                      key={i}
                      title={profile.title}
                      thumbnailSrc={profile.featuredImage}
                      href={profile.slug}
                      className={styles.teamPin}
                    >
                      {profile.additionalInfo && (
                        <strong>{profile.additionalInfo}</strong>
                      )}
                      <p>{profile.excerpt}...</p>
                    </AggregatorPin4>
                  </>
                )
              })}
            </PinBlockGroup>
          </div>

          <Divider thickness={4} />
          <Typography tag="h1" type="boxHeading" className="mb-8">
            BERTHA CENTRE SCHOLARS (2022)
          </Typography>
          <div>
            <PinBlockGroup key={1} columns={2}>
              {profilesOnlyScholars2022.map((profile, i) => {
                return (
                  <>
                    <AggregatorPin4
                      key={i}
                      title={profile.title}
                      thumbnailSrc={profile.featuredImage}
                      href={profile.slug}
                      className={styles.teamPin}
                    >
                      {profile.additionalInfo && (
                        <strong>{profile.additionalInfo}</strong>
                      )}
                      <p>{profile.excerpt}...</p>
                    </AggregatorPin4>
                  </>
                )
              })}
            </PinBlockGroup>
          </div>

          <Divider thickness={4} />
          <Typography tag="h1" type="boxHeading" className="mb-8">
            BERTHA CENTRE SCHOLARS (2020/2021)
          </Typography>
          <div>
            <PinBlockGroup key={1} columns={2}>
              {profilesOnlyScholarsPre2022.map((profile, i) => {
                return (
                  <>
                    <AggregatorPin4
                      key={i}
                      title={profile.title}
                      thumbnailSrc={profile.featuredImage}
                      href={profile.slug}
                      className={styles.teamPin}
                    >
                      {profile.additionalInfo && (
                        <strong>{profile.additionalInfo}</strong>
                      )}
                      <p>{profile.excerpt}...</p>
                    </AggregatorPin4>
                  </>
                )
              })}
            </PinBlockGroup>
          </div>
        </div>
        <div>
          <Social orientation="horizontal" />
          <Divider thickness={4} />
          <PressOfficeWidget />
        </div>
      </ContentRightSidebar>
    </>
  )
}

ScholarPage.propTypes = {
  data: PropTypes.shape({
    aboutPage: PropTypes.any,
    profiles: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ScholarPage

export const pageQuery = graphql`
  query OurScholarsPageQuery {
    aboutPage: mdx(fields: { slug: { eq: "/about-us/" } }) {
      frontmatter {
        ...headerFields
      }
    }
    profiles: allMdx(
      filter: { fields: { contentType: { eq: "teamProfiles" } } }
      sort: { fields: frontmatter___publishedDate, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            additionalInfo
            teamCategory
            excerpt
            editor
            featuredImage {
              childCloudinaryMediaImage {
                fixed(
                  width: 150
                  transformations: "w_150,h_150,c_thumb,g_face"
                ) {
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
